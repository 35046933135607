var CLOSED_ARROW = '<i class="glyphicon glyphicon-triangle-right"></i>';
var OPEN_ARROW = '<i class="glyphicon glyphicon-triangle-bottom"></i>';
var DEPTH_INDICATOR = '-';

function formatHierarchy(hierarchy) {
    var rootNode = null;

    if (hierarchy.length > 0) {
        for (var i = 0; i < hierarchy.length; i++) {
            // Init the nodes array
            if(hierarchy[i].children === undefined) {
                hierarchy[i].children = [];
            }
            // Find previous item who is parent of current item.
            for(var j = i - 1; j >= 0; j--) {
                if(hierarchy[i].ParentCompanyID === hierarchy[j].CompanyID) {
                    hierarchy[j].children.push(hierarchy[i]);
                    break;
                }
            }
        }
        rootNode = hierarchy[0];
    }

    return rootNode;
}

function renderDropdownTree(rootElement, rootNode, hideRootRegion) {
    if(rootNode) {
        $(rootElement).wrap('<div class="reportingtree-container"></div>');
        $(rootElement).addClass('reportingtree-dropdown');
        $(rootElement).hide();
        $(rootElement).parent().prepend('<div class="reportingtree-selector">');
        $('.reportingtree-selector').append('<span class="reportingtree-selected-item">Select user\'s ' + reporting_label + '</span>');
        $(rootElement).parent().append('<input type="hidden" value="" name="reporting_location" id="reporting_location">');
        $(rootElement).append('<ul />');
        var currentElement = $(rootElement).find('ul');
        renderSelection(currentElement, rootNode, hideRootRegion);
        findInitialSelection();

        $(rootElement).on('click', '.reportingtree-expand', function(e){
            e.stopPropagation();
            $(this).empty();
            if($(this).parents('li').first().find('ul').first().is(':visible')){
                $(this).prepend(CLOSED_ARROW);
                $(this).parents('li').first().find('ul').first().hide();
            }else{
                $(this).prepend(OPEN_ARROW);
                $(this).parents('li').first().find('ul').first().show();
            }
        });

        $(rootElement).on('click', '.reportingtree-text', function(e){
            e.stopPropagation();
            if(selectItem($(this))) {
              $(rootElement).hide();
            }
        });

        $('.reportingtree-container').on('click', '.reportingtree-selector', function(e){
            e.stopPropagation();
            if($(rootElement).is(':visible')) {
                $(rootElement).hide();
            } else {
                $(rootElement).show();
            }
        });

        $('body').click(function() {
        if (!$(this.target).is('.reportingtree-container')) {
            $(rootElement).hide();
        }
        });
    }
}

function selectItem(item) {
  if($(item).hasClass('reportingtree-disabled')) {
    var expandButton = $(item).parent().children('.reportingtree-expand');
    if(expandButton.length) {
      expandButton.trigger('click');
    }
    return false;
  }

  $('.reportingtree-selector').html($(item).html());
  $('input[name=reporting_location]').attr('value', $(item).parent().data('company'));
  $('.reportingtree-active').removeClass('reportingtree-active');
  $(item).addClass('reportingtree-active');
  $('.reportingtree-active').closest('ul').show();
  $('.reportingtree-active').closest('ul').parent().children('.reportingtree-expand').first().empty().prepend(OPEN_ARROW);
  return true;
}

function renderSelection(element, node, hideRootRegion) {
    var button = node.children.length > 0 && node.Depth > 1 ? '<span class="reportingtree-expand">' + CLOSED_ARROW + '</span>' : '';
    var itemLeaf = node.children.length === 0 ? ' reportingtree-leaf' : '';
    var itemDepth = node.Depth > 2 ? ' reportingtree-child' : '';
    var disabled = node.enabled ? '' : ' reportingtree-disabled';
    var hidden = (hideRootRegion && node.Depth === '1') ? ' reportingtree-hidden' : '';

    $(element).append('<li class="reportingtree-item" data-company="' + node.CompanyID + '" data-parent="' + node.ParentCompanyID + '" data-depth="' + node.Depth + '">' + button + ' <span class="reportingtree-text' + itemLeaf + itemDepth + disabled + hidden + '">' + DEPTH_INDICATOR.repeat((node.Depth * 2) - 2) + node.CompanyName + '</span></li>');
    if(node.children.length > 0) {
        var newElement = $('li[data-company=' + node.CompanyID + ']');

        if(node.Depth >= 2) {
            newElement.append('<ul style="display:none"></ul>');
        } else {
            newElement.append('<ul />');
        }

        node.children.forEach(function(child) {
            renderSelection(newElement.find('ul').first(), child, hideRootRegion);
        });
    }
}

function findInitialSelection() {
    var selected = $('[data-company="' + selectedCompanyID + '"]').find('.reportingtree-text').first();
    selectItem(selected);
}
